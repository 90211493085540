@import '../../../styles/all.scss';

.showToast {
  animation: toastScrollDown 200ms ease-in-out forwards;

  @include for-lg-tablet {
    animation: toastScrollUp 200ms ease-in-out forwards;
  }
}

.hideToast {
  animation: toastFadeOut 200ms ease-in-out forwards;
}

.boxShadowForToast {
  box-shadow: 0px 1px 4px rgba(27, 27, 24, 0.1),
    0px 4px 6px rgba(27, 27, 24, 0.04), 0px 8px 16px rgba(27, 27, 24, 0.04),
    0px 10px 20px 2px rgba(27, 27, 24, 0.02),
    0px 12px 24px 4px rgba(27, 27, 24, 0.04);
}

@keyframes toastScrollUp {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes toastScrollDown {
  0% {
    opacity: 0;
    transform: translateY(-32px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes toastFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
