@font-face {
  font-family: Metropolis;
  src: url(../assets/metropolis.extra-bold.otf);
}

$font-tiktok: 'Metropolis', sans-serif;
$font-title: 'Poppins', sans-serif;
$font-regular: Helvetica, sans-serif;
$font-base: 'Poppins', sans-serif;
$font-poppin: 'Poppins', sans-serif;
