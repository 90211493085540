@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-section-bg: #ffffff;
    --color-card-bg: #ffffff;
    --color-text-base: #333333;
    --color-text-emphasized: #000000;

    /* --color-text-base-alt: #333333; */
    --color-text-base-alt: 51, 51, 51; /* rgb for #333333 */
    --color-text-brand-emphasized: #333333;
    --color-border-base: #e2e2e2;

    --color-price-base: #e08700;
    --color-course-base: #666666;
    --color-date-base: #333333;
    --color-hr-base: #e0e0e0;
    --color-btn-base: #2c2c2c;
  }

  .theme-dark {
    --color-section-bg: #2c2c2c;
    --color-card-bg: #444444;
    --color-text-base: #aaaaaa;
    --color-text-emphasized: #f2f2f2;
    /* --color-text-base-alt: #f2f2f2; */
    --color-text-base-alt: 242, 424, 424; /* rgb for #f2f2f2; */
    --color-text-brand-emphasized: #fbc91b;
    --color-border-base: #444;

    --color-price-base: #f2f2f2;
    --color-course-base: #aaaaaa;
    --color-date-base: #f2f2f2;
    --color-hr-base: #444444;
    --color-btn-base: #f2f2f2;
  }
}

@layer components {
  .carousel-nav-btn-shadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
  }
}

@layer utilities {
  .scroll-snap-none {
    scroll-snap-type: none;
  }

  .scroll-snap-x {
    -ms-scroll-snap-type: x;
    scroll-snap-type: x;
  }

  .scroll-snap-x-mandatory {
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
  }

  .scroll-snap-y {
    -ms-scroll-snap-type: y;
    scroll-snap-type: y;
  }

  .scroll-smooth {
    scroll-behavior: smooth;
  }

  .scroll-snap-center {
    scroll-snap-align: center;
  }

  .scroll-snap-start {
    scroll-snap-align: start;
  }

  .max-w-848 {
    max-width: 848px;
  }

  .h-full-dvh {
    height: 100vh; /* fallback for browsers that don't support 100dvh */
    height: 100dvh; /* fallback for browsers that don't support dvh */
  }

  .desktop-only {
    @apply hidden xl:block;
  }

  .mobile-only {
    @apply block xl:hidden;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .small-scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .small-scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
      background: transparent;
  }

  .small-scrollbar::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 100vh;
      border: 3px solid #666666;
  }

  .small-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #444444;
  }
}

.section-title {
  @apply font-poppins font-medium text-14 xl:text-18 uppercase text-orange-default my-12 mx-0;
}

.title-hero {
  @apply text-48 font-bold;
}

/* .section-title {
  @apply font-poppins font-medium text-orange-default text-14 xl:text-18;
} */

.common-style {
  @apply font-poppins text-14 xl:text-48;

  font-size: 48px;
}

.page-section-padding {
  @apply py-24 px-16 xl:py-48 xl:px-80;
}

.react-datepicker {
  border: none !important;
}

.container-timePicker_date .react-datepicker__input-container input {
  font-family: 'Poppins', sans-serif;
  padding: 10px 12px;
  border: 1px solid #dbdada;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  text-transform: lowercase;
  width: 110px;
}

/* .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #1a202c !important;
  border-radius: 50% !important;
} */

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  position: absolute !important;
  top: 19px;
  left: -107px;
  padding-right: 25px;
  width: 202px !important;
}

.container-timePicker_date
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  font-family: 'Poppins', sans-serif;
  width: 100% !important;
  text-align: left !important;
  margin: 0 !important;
  text-transform: lowercase;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 40px !important;
  display: flex;
  align-items: center;
  padding: 5px 15px !important;
}

.container-timePicker_date
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-container .react-datepicker__time {
  box-shadow: 0px 3px 28px rgb(0 0 0 / 8%);
  border-radius: 4px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f0f0f0 !important;
  color: #000 !important;
  font-weight: normal !important;
}

/* .react-datepicker__month-container {
  height: 330px;
} */

/* .react-datepicker__day--selected {
  background-color: #1a202c !important;
  border-radius: 50% !important;
} */

.react-datepicker__day:hover {
  background-color: #edf2f7 !important;
  border-radius: 50% !important;
}

/* .react-datepicker__navigation--previous {
  left: auto !important;
  right: 40px !important;
} */

/* .react-datepicker__current-month {
  font-weight: 500 !important;
  width: 60%;
} */

.footer-icon {
  margin: auto;
}

.footer-icon path {
  fill: #aaaaaa;
  fill-opacity: 1;
}
