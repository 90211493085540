$primary-color: #fbc91b;
$primary-color-light: #fceebe;
$dark-color: #2c2c2c;
$alt-dark-text: #333333;
// $danger-color: #ff2750;
$danger-color: #db3952;
$promo-color: #fe6361;
$alt-text-color: rgba(black, 0.5); // TODO investigate if this correct
$alt-text-color-v2: rgba(255, 255, 255, 0.5);
$dark-text: rgba(black, 0.8);
$black-text: rgba(black, 1);
$light-text: #f6f6f6;
$alt-section-bg-color: #f6f6f6;
$gray-text: rgba(80, 80, 80, 0.4);
$white-text: #ffffff;
$alt-white: rgba(255, 255, 255, 0.5);
$alt-grey: #666666;
$dark-grey: rgba(170, 170, 170, 1);
$alt-dark-text-color: rgba(51, 51, 51, 1);

$alert-color: #db3952;

$form-btn-primary-bg-color: #fbc91b;
$form-btn-primary-text-color: #000000;

$form-btn-secondary-bg-color: #e0e0e0;
$form-btn-secondary-text-color: #000000;
$form-btn-secondary-alt-text-color: $alt-text-color;

$form-payment-el-light-bg: #f6f6f6;

$success-bg-color: #e2f4ec;
$success-text-color: #4a9d77;
$green-alert-color: #007645;
$disabled-button-bg-color: #e0e0e0;
$disabled-button-text-color: rgba(0, 0, 0, 0.5);

$disabled-bg-color: #f2f2f2;

$base-shadow: 0px 3px 28px rgba(0, 0, 0, 0.08);
$base-strong-shadow: 0px 3px 28px rgba(0, 0, 0, 0.12);
$drop-shadow: drop-shadow(0px 3px 28px rgba(0, 0, 0, 0.08));

$section-spacing-desktop: 48px 80px;
$section-spacing-mobile: 24px 16px;

$header-height: 64px;
$header-desktop-height: 80px;

$gift-section-height: 38px;
$gift-section-desktop-height: 80px;

$font-weight-regular: 400;
$font-weight-bold: 800;
$font-weight-bolder: 900;

$std-border-radius: 12px;

$form-el-bg-color: #f6f6f6;
$form-el-error-bg-color: #ffebef;
$form-select-v2-bg-color: #fff;

$fb-primary-color: #3f8af9;
$ig-primary-color: linear-gradient(
  -90deg,
  #fccc63 0%,
  #e95950 48.96%,
  #9a4ead 100%
);
$yt-primary-color: #ff0000;
$grab-primary-color: #00b14f;

$light-gray: rgba(white, 0.56);
$bright-white: rgba(white, 0.88);

$carousel-dot-color: #c4c4c4;
$carousel-dot-active-color: $dark-text;

$da-primary-color: #ff769d;
$da-secondary-color: #ffc7d7;
$da-third-color: #ffc4d5;
$da-light-bg-color: #fff5f8;
$da-gray-text: rgba(80, 80, 80, 0.5);

$tiktok-primary-color: #ee1d52;
$tiktok-secondary-color: #69c9d0;
$tiktok-dark: #010101;

$birthday-bg-gradient: linear-gradient(
  90.76deg,
  #fbb034 0%,
  #fbb034 0%,
  #fbc91b 99.92%
);

$markian-green-color: #97fe91;
$markian-dark-color: #3e3e3e;
$markian-white-text: rgba(255, 255, 255, 0.92);

$whatsapp-color: #04c74d;

$dark-color-v2: #444444;
$aos-dark-card: $dark-color-v2;

$disabled-tab-text-color: #c4c4c4;

$gold-color: #e08700;

$mx-blue-color: #3c8cf0;
$mx-blue-pale-color: rgba(60, 140, 240, 0.5);
$mx-red-color: #fb5460;

$gold-bg-color: rgba(251, 201, 27, 0.1);
$gold-bg-text-color: #e08700;
$gold-text-dark-color: #a76400;

$gold-hover-color: #d6a600;
